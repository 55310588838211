import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IScreenerOutput = {
  pId: string | null; // patient id received from survey
  pdfContent: string | null; // Response to all questions in base64 received from survey
  pSec: string | null; // protocol assigned from survey
  r: string | number | null; // high risk indicator received from survey
  ed: string | number | null; // year of education received from survey
  g: string | null; // response filled in survey
  d: string | null; // response filled in survey
  m: string | null; // response filled in survey
  y: string | null; // response filled in survey
  zipCode: string | number | null; // response filled in survey
  cm: string | null; // comorbidity received from survey
  b: string | null; // bmi received from survey
  et: string | null; // ethnicity received from survey
  ch: string | null; // cohort received from survey
  patientType: string | null; // adult, minor, caregiver received from survey
  ac: string | null; // associated conditions received from survey
  lastQuestion: boolean | null; // will be true if the response is from lastQuestion
  redirectTo: string | null; // to identify which type of action being performed from survey for eg, RedirectToSuccess, RedirectToFailure, RedirectToMinorDNQ etc
  genericAnswers: {
    Pval: string | null;
    Cval: string | null;
    Dval: string | null;
  }; // to identify which type of action being performed from survey for eg, RedirectToSuccess, RedirectToFailure, RedirectToMinorDNQ etc
  condition: string | null;
};

const initialState: IScreenerOutput = {
  pId: null,
  pdfContent: null,
  pSec: null,
  r: null,
  ed: null,
  g: null,
  d: null,
  m: null,
  y: null,
  zipCode: null,
  cm: null,
  b: null,
  et: null,
  ch: null,
  patientType: null,
  ac: null,
  lastQuestion: null,
  redirectTo: null,
  genericAnswers: {
    Pval: null,
    Cval: null,
    Dval: null,
  },
  condition: null,
};

// type Payload = { [key: string]: string | null };
const screenerOutputSlice = createSlice({
  name: 'screenerOutput',
  initialState,
  reducers: {
    addScreenerOutput: (state, action: PayloadAction<IScreenerOutput>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { addScreenerOutput } = screenerOutputSlice.actions;
export default screenerOutputSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  userName: '',
  selectedFilters: {},
  selectedProtocol: '',
  selectedProtocolItemId: '',
  fieldOptions: [],
  eprFieldOptions: [],
  siteStatusOptions: [],
  countryStatesOptions: [],
  refreshSiteConfig: {
    optedIn: false,
    radius: 10,
    //default priority is blank
    priority: '',
    threshold: 10,
    warmTransferNumber: '',
    optInDateTime: '',
    isDefaultVirtualSite: false,
    isHybridSite: false,
    kiElementsEnabled: false,
    siteDirectedSchedule: false,
  },
};

const smaSlice = createSlice({
  name: 'sma',
  initialState,
  reducers: {
    setUserName: (state, action) => ({
      ...state,
      userName: action.payload,
    }),
    updateSelectedFilters: (state, action) => ({
      ...state,
      selectedFilters: action.payload,
    }),
    updateSelectedProtocol: (state, action) => ({
      ...state,
      selectedProtocol: action.payload,
    }),
    updateProtocolItemId: (state, action) => ({
      ...state,
      selectedProtocolItemId: action.payload,
    }),
    setFieldOptions: (state, action) => ({
      ...state,
      fieldOptions: action.payload,
    }),
    setEprFieldOptions: (state, action) => ({
      ...state,
      eprFieldOptions: action.payload,
    }),
    setRefreshSiteConfig: (state, action) => ({
      ...state,
      refreshSiteConfig: action.payload,
    }),
    setSiteStatusOptions: (state, action) => ({
      ...state,
      siteStatusOptions: action.payload,
    }),
    setCountryStatesOptions: (state, action) => ({
      ...state,
      countryStatesOptions: action.payload,
    }),
  },
});

export const {
  setUserName,
  updateSelectedFilters,
  updateSelectedProtocol,
  updateProtocolItemId,
  setFieldOptions,
  setEprFieldOptions,
  setSiteStatusOptions,
  setRefreshSiteConfig,
  setCountryStatesOptions,
} = smaSlice.actions;
export default smaSlice.reducer;

import pino, { Logger } from 'pino';

const createDevLogger = (): Logger => {
  return pino({
    enabled: !(process.env.NEXT_PUBLIC_SPLUNK_LOG_ENABLED === 'false'),
    transport: {
      targets: [
        {
          level: process.env.NEXT_PUBLIC_SPLUNK_LOG_LEVEL || 'info',
          target: 'pino/file',
          options: {
            destination: process.env.NEXT_PUBLIC_PINO_LOG_FILE_PATH || 'src/logger/error-logs.log',
            append: true,
          },
        },
      ],
    },
  });
};

export { createDevLogger };
